import React, { Component } from "react";
import { Fade } from "react-reveal";
import "./TestimonialCard.css";

class TestimonialCard extends Component {
  render() {
    const { testimonial, theme } = this.props;

    return (
      <Fade bottom duration={2000} distance="20px">
        <div className="testimonial-card">
            <img
              src={testimonial.image}
              alt={testimonial.name}
              className="testimonial-author-image"
            />
          <div className="testimonial-content">
            
            <h3 className="testimonial-author" style={{ color: theme.text }}>
             - {testimonial.name}, <br></br> {testimonial.role} at {testimonial.company},
            </h3>

            <p className="testimonial-text" style={{ color: theme.text }}>
              "{testimonial.text}"
            </p>
          </div>
        </div>
      </Fade>
    );
  }
}

export default TestimonialCard;