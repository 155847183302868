import React, { Component } from "react";
import "./TestimonialList.css";

import TestimonialCard from "../../components/testimonialCard/TestimonialCard";
import { testimonials } from "../../portfolio";

class TestimonialList extends Component {
  render() {
    const { theme } = this.props;
    
    return (
      <div className="testimonial-list">
        <div className="testimonial-list-body">
          {testimonials.data.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              testimonial={testimonial}
              theme={theme}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default TestimonialList;