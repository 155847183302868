import React, { Component } from "react";
import { Fade } from "react-reveal";
import "./TestimonialsPage.css";

import TestimonialList from "../../containers/testimonialsList/TestimonialList";
import TopButton from "../../components/topButton/TopButton";
import { testimonialsHeader } from "../../portfolio";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

// Import testimonialsHeader

class TestimonialsPage extends Component {
  render() {
    const { theme, onToggle } = this.props;

    return (
      <div>
        <Header theme={theme} />
        <div className="basic-testimonials">
          <Fade bottom duration={2000} distance="40px">
            <div className="testimonials-heading-div">
              <div className="testimonials-heading-text-div">
                <h1
                  className="testimonials-heading-text"
                  style={{ color: theme.text }}
                >
                  {testimonialsHeader.title} {/* Using testimonialsHeader */}
                </h1>
                <p
                  className="testimonials-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {testimonialsHeader.description} {/* Using testimonialsHeader */}
                </p>
              </div>
            </div>
          </Fade>
          <TestimonialList theme={theme} />
        </div>
        <Footer theme={theme} onToggle={onToggle} />
        <TopButton theme={theme} />
      </div>
    );
  }
}

export default TestimonialsPage;