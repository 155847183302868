// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

// SEO Related settings
const seo = {
  title: "Pradeep's Portfolio",
  description:
    "A passionate data scientist who thrives on working with end-to-end products, developing sustainable and scalable data-driven systems to create meaningful social and technical impact.",
  og: {
    title: "Pradeepchandra Reddy's Portfolio",
    type: "website",
    url: "https://soopertramp.com/",
  },
};

// Home Page
const greeting = {
  title: "Pradeepchandra Reddy S C",
  logo_name: "Pradeepchandra",
  nickname: "soopertramp",
  subTitle:
    "A passionate data scientist who thrives on working with end-to-end products, developing sustainable and scalable data-driven systems to create meaningful social and technical impact.",
  resumeLink: "",
  linkedin_link_for_follow: "https://www.linkedin.com/in/soopertramp/",
  githubProfile: "https://github.com/soopertramp/",
};

// Social Media Links
const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/soopertramp/",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/soopertramp/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Mail",
    link: "mailto:contact@soopertramp.com",
    fontAwesomeIcon: "fa-solid fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Gmail",
    link: "mailto:pradeepchandrareddy95@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://twitter.com/soopertramp07/",
    fontAwesomeIcon: "fa-twitter", // Use this for Twitter (now X)
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/soopertramp07/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
  {
    name: "Medium",
    link: "https://medium.com/@pradeepchandrareddy95/",
    fontAwesomeIcon: "fa-m", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=instagram
  },
];

// What I do

const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing scalable data analytics solutions to enhance business insights and decision-making",
        "⚡ Experience in Generative AI and NLP projects, applying cutting-edge generative AI technologies",
        "⚡ Utilizing advanced analytical techniques for forecasting, campaign optimization, and boosting customer engagement and retention",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Machine Learning",
          fontAwesomeClassname: "carbon:machine-learning",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Numpy",
          fontAwesomeClassname: "logos:numpy",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
        {
          skillName: "Pandas",
          fontAwesomeClassname: "devicon:pandas",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
        {
          skillName: "Matplotlib",
          fontAwesomeClassname: "devicon:matplotlib",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
        {
          skillName: "Scikit Learn",
          fontAwesomeClassname: "devicon:scikitlearn",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
        {
          skillName: "Pytorch",
          fontAwesomeClassname: "devicon:pytorch",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
        {
          skillName: "Langchain",
          fontAwesomeClassname: "simple-icons:langchain",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "devicon:flask-wordmark",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Postman",
          fontAwesomeClassname: "devicon:postman",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "OpenAI",
          fontAwesomeClassname: "logos:openai-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Deeplearning",
          imageSrc: "deeplearning_ai_logo.png",
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "devicon:keras",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos:tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "Data Analytics",
      fileName: "FullStackImg",
      skills: [
        "⚡ Creating and maintaining live dashboards using Power BI and Tableau to provide real-time, actionable insights to stakeholders across the organization",
        "⚡ Streamlining data cleaning processes to enhance data quality and increase processing efficiency",
        "⚡ Preparing comprehensive insights reports that guide strategic decisions and contribute to revenue growth and operational efficiency",
      ],
      softwareSkills: [
        {
          skillName: "Zoho",
          fontAwesomeClassname: "logos:zoho",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Power BI",
          fontAwesomeClassname: "logos:microsoft-power-bi",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Tableau",
          fontAwesomeClassname: "logos:tableau-icon",
          style: {
            color: "#CC6699",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Worked on Azure OpenAI and Assistants",
        "⚡ Experience is using AWS and Azure for creating GPT assistant bots",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "SQL Alchemy",
          fontAwesomeClassname: "devicon:sqlalchemy-wordmark",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "devicon:mysql-wordmark",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "Streamlit",
          fontAwesomeClassname: "logos:streamlit",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    // {
    //   title: "UI/UX Design",
    //   fileName: "DesignImg",
    //   skills: [
    //     "⚡ Designing highly attractive user interface for mobile and web applications",
    //     "⚡ Customizing logo designs and building logos from scratch",
    //     "⚡ Creating the flow of application functionalities to optimize user experience",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Adobe XD",
    //       fontAwesomeClassname: "simple-icons:adobexd",
    //       style: {
    //         color: "#FF2BC2",
    //       },
    //     },
    //     {
    //       skillName: "Figma",
    //       fontAwesomeClassname: "simple-icons:figma",
    //       style: {
    //         color: "#F24E1E",
    //       },
    //     },
    //     {
    //       skillName: "Adobe Illustrator",
    //       fontAwesomeClassname: "simple-icons:adobeillustrator",
    //       style: {
    //         color: "#FF7C00",
    //       },
    //     },
    //     {
    //       skillName: "Inkscape",
    //       fontAwesomeClassname: "simple-icons:inkscape",
    //       style: {
    //         color: "#000000",
    //       },
    //     },
    //   ],
    // },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Github",
      iconifyClassname: "icon-park:github",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://github.com/soopertramp/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/soopertramp07",
    },
    {
      siteName: "Tableau",
      iconifyClassname: "logos:tableau-icon",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://public.tableau.com/app/profile/pradeepchandra.reddy.s.c/vizzes",
    },
    {
      siteName: "Medium",
      iconifyClassname: "fa6-brands:medium",
      style: {
        color: "#000000",
      },
      profileLink: "https://medium.com/@pradeepchandrareddy95",
    },
    {
      siteName: "LinkedIN",
      iconifyClassname: "skill-icons:linkedin",
      style: {
        color: "#323754",
      },
      profileLink: "https://linkedin.com/in/soopertramp",
    },
    {
      siteName: "Leetcode",
      iconifyClassname: "tabler:brand-leetcode",
      style: {
        color: "#f9b339",
      },
      profileLink: "https://leetcode.com/u/soopertramp07/",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Visvesvaraya Technological University (VTU)",
      subtitle: "B.E in Mechanical Engineering",
      logo_path: "vtu_logo.png",
      alt_name: "vtu logo",
      duration: "2013 - 2017",
      descriptions: [
        "⚡ I have studied fundamental mechanical engineering subjects like Thermodynamics, Fluid Mechanics, Mechanics of Materials, Machine Design, Control Systems, and Heat Transfer.",
        "⚡ Apart from this, I have completed courses on Data Science, Machine Learning, Robotics, Finite Element Analysis, Computational Fluid Dynamics, and Mechatronics.",
        "⚡ I led a team that won first place in a national Robo Wars competition, where we applied data analytics to optimize our robot's performance. Additionally, I worked on developing a solar-powered air conditioner, integrating data science techniques to enhance its efficiency.",
      ],
      website_link: "http://vtu.ac.in",
    },
    {
      title: "Simplilearn",
      subtitle: "M.S. in Data Science",
      logo_path: "simplilearn1.png",
      alt_name: "Simplilearn",
      duration: "2022 - 2023",
      descriptions: [
        "⚡ Built a strong foundation in data science concepts and practical skills.",
        "⚡ Proficient in Excel for Data Analysis, SQL for Data Science, and Python Programming for Data Science.",
        "⚡ Studied Statistics and Probability alongside Machine Learning techniques.",
      ],
      website_link: "https://www.simplilearn.com/big-data-and-analytics/senior-data-scientist-masters-program-training#:~:text=Data%20Science%20Course%20Overview",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Python Essential Training",
      subtitle: "- Bill Weinman",
      logo_path: "linkedin.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/0533f9a3570f48fd5582abe37bdfcdce63f3675803ab6648d74fd8b5757c72da?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3B%2B2KjGGBBRke%2BohHAgofokg%3D%3D",
      alt_name: "linkedin",
      color_code: "#2A73CC",
    },
    {
      title: "Machine Learning Advanced Certification",
      subtitle: "- Simplilearn",
      logo_path: "simplilearn.png",
      certificate_link:
        "https://certificates.simplicdn.net/share/thumb_3840135.png",
      alt_name: "simplilearn",
      color_code: "#f2edec",
    },
    {
      title: "SQL",
      subtitle: "- Simplilearn",
      logo_path: "simplilearn.png",
      certificate_link:
        "https://certificates.simplicdn.net/share/thumb_3950102.png",
      alt_name: "simplilearn",
      color_code: "#f2edec",
    },
    {
      title: "GitHub for Data Scientists",
      subtitle: "- Sara Anstey",
      logo_path: "linkedin.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/e48beddfbc218bc1d7e76e95e21cfc02b9ddbbc92e335d87023067736aae5192?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3BJ0p2HVmhRUeGn0qbJwPFdg%3D%3D",
      alt_name: "linkedin",
      color_code: "#2A73CC",
    },
    {
      title: "Flask",
      subtitle: "- Nick Walter",
      logo_path: "linkedin.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/dab2d296ab25a3ab111ae8c94725ed3e48c7041742500e2f288648c9163087f3?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3BJ0p2HVmhRUeGn0qbJwPFdg%3D%3D",
      alt_name: "linkedin",
      color_code: "#2A73CC",
    },
    {
      title: "SQL (Advanced)",
      subtitle: "- Hacker Rank",
      logo_path: "hackerrank.png",
      certificate_link:
        "https://www.hackerrank.com/certificates/3ea5dd2c4efa",
      alt_name: "hackerrank",
      color_code: "#f2edec",
    },
    {
      title: "SSIS",
      subtitle: "- Adam Wilbert",
      logo_path: "linkedin.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/d723b15290279d4adbb401b2903539a6230a374d4e6d029c9ef258b92baf8162?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3BJ0p2HVmhRUeGn0qbJwPFdg%3D%3D",
      alt_name: "linkedin",
      color_code: "#2A73CC",
    },
    {
      title: "Machine Learning",
      subtitle: "- Andrew NG",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://coursera.org/share/e50d758b7975a3a97d483c428287f4a3",
      alt_name: "stanford",
      color_code: "#8C1515",
    },
    // {
    //   title: "Fullstack Development",
    //   subtitle: "- Jogesh Muppala",
    //   logo_path: "coursera_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
    //   alt_name: "Coursera",
    //   color_code: "#2A73CC",
    // },
    // {
    //   title: "Kuberenetes on GCP",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
    // {
    //   title: "Cryptography",
    //   subtitle: "- Saurabh Mukhopadhyay",
    //   logo_path: "nptel_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
    //   alt_name: "NPTEL",
    //   color_code: "#FFBB0099",
    // },
    {
      title: "PL-300 certification: Microsoft Power BI Data Analyst",
      subtitle: "- Phillip Burton",
      logo_path: "udemy.png",
      certificate_link:
        "",
      alt_name: "udemy",
      color_code: "#ffffff",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Internship",
  description:
    "Data scientist with over a year of experience optimizing data processes and improving business metrics in startups and established companies. Proficient in Python, machine learning libraries (TensorFlow, PyTorch, scikit-learn), and generative AI models (GPT-3, GPT-4). Passionate about developing scalable data-driven systems for meaningful impact.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Data Scientist",
          company: "Elfonze Technologies",
          company_url: "https://www.elfonze.com/",
          logo_path: "elfonze_logo.png",
          duration: "Nov 2023 - Present",
          location: "Bengaluru, KA, India",
          description:
            "Engineered advanced Power BI dashboards for stakeholders, developed the ElfonzeGPT organizational chatbot for company queries, and created a resume-matching system with an 80% success rate, enhancing data-driven decisions and operational efficiency, etc. Working on Large Language Models (LLM) pretraining and Large Multi-modal Model (LMM) finetunings.",
          color: "#ffffff",
        },
        // {
        //   title: "Associate AI Engineer",
        //   company: "Legato Health Technology",
        //   company_url: "https://legatohealthtech.com/",
        //   logo_path: "legato_logo.png",
        //   duration: "June 2020 - Aug 2021",
        //   location: "Hyderabad, Telangana",
        //   description:
        //     "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
        //   color: "#0879bf",
        // },
        // {
        //   title: "Android and ML Developer",
        //   company: "Muffito Incorporation",
        //   company_url: "https://www.linkedin.com/company/muffito-inc/about/",
        //   logo_path: "muffito_logo.png",
        //   duration: "May 2018 - Oct 2018",
        //   location: "Pune, Maharashtra",
        //   description:
        //     "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
        //   color: "#9b1578",
        // },
        // {
        //   title: "Android Developer",
        //   company: "FreeCopy Pvt. Ltd.",
        //   company_url: "https://www.linkedin.com/company/freecopy/about/",
        //   logo_path: "freecopy_logo.png",
        //   duration: "Nov 2017 - Dec 2017",
        //   location: "Ahmedabad, Gujarat",
        //   description:
        //     "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
        //   color: "#fc1f20",
        // },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Data Analysis and Report Preparation Intern",
          company: "India Electronics and Semiconductors Association",
          company_url: "https://www.iesaonline.org/",
          logo_path: "iesa_logo.png",
          duration: "Jul 2023 - Nov 2023",
          location: "Bengaluru, KA, India",
          description:
            "Optimized data processes, developed dashboards and reports, and automated workflows—reducing errors by 25%, boosting efficiency by 30%, saving 15 hours weekly, improving operational efficiency by 20%, and increasing customer satisfaction.",
          color: "#000000",
        },
        {
          title: "Marketing Data Analyst",
          company: "Data To Production",
          company_url: "https://www.datatoproduction.com/",
          logo_path: "d2p_logo.png",
          duration: "Feb 2023 - Jul 2023",
          location: "Remote",
          description:
            "As a Marketing Data Analyst, I've successfully boosted customer acquisition by 15% and improved retention rates by 10% through data-driven insights. By optimizing campaigns, I've achieved a significant 25% increase in conversion rates.",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Data Science Career Mentor",
          company: "Topmate",
          company_url: "https://topmate.io/soopertramp",
          logo_path: "topmate_logo.png",
          duration: "Nov 2023 - Present",
          location: "Remote",
          description:
            "As a Data Science Career Mentor at Topmate, I provide personalized guidance to aspiring data scientists for free, helping them navigate the complexities of the field. Through tailored mentorship, I aim to empower individuals to achieve their professional goals in data science.",
          color: "#4285F4",
        },
        // {
        //   title: "Microsoft Student Partner",
        //   company: "Microsoft",
        //   company_url: "https://www.microsoft.com/",
        //   logo_path: "microsoft_logo.png",
        //   duration: "Aug 2019 - May 2020",
        //   location: "Hyderabad, Telangana",
        //   description:
        //     "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
        //   color: "#D83B01",
        // },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "Yet to Update",
      name: "Yet to Update",
      createdAt: "Yet to Update",
      description: "Yet to Update",
      url: "",
    },
  ],
};

// Testimonials Page
const testimonialsHeader = {
  title: "Testimonials",
  description:
    "Here are some kind words from people I’ve worked with. Their feedback highlights my passion for developing meaningful projects and working collaboratively.",
  avatar_image_path: "blogs_image.svg",
};

const testimonials = {
  data: [
    {
      id: "testimonial-1",
      name: "Aishit Dharwal",
      role: "Senior Data Scientist (AI Labs)",
      company:"IDFC First Bank",
      text: "I worked with Pradeep at Data to Production where he handled Marketing Analytics. I recommend Pradeep for all Analytics roles and he will be a great fit!",
      // createdAt: "2023-05-15T00:00:00Z",
      image: "https://media.licdn.com/dms/image/v2/D4D03AQEyBN4GN1Z5Bg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1690718160641?e=1732147200&v=beta&t=XDrcaXgFmevYLHQ0w2zBB7ZQb1mluz0WdsEDZ-SXSzM"
    },
    {
      id: "testimonial-2",
      name: "Saurabh Kumar",
      role: "Senior Data Architect",
      company:"American Express",
      text: "PC's amazing affection in Data Domain is a blend of his curious mind and creative ideas. He holds a very good command in analytics and has expertise in all the relevant tools required.",
      // createdAt: "2023-05-15T00:00:00Z",
      image: "https://media.licdn.com/dms/image/v2/D4D03AQGn0ljaMIrVhA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1695538367032?e=1732147200&v=beta&t=Zjk2ekdk5LjSaOKjkVc2cgwrayskVHHryYD_ApmhNiY"
    },
    {
      id: "testimonial-3",
      name: "Mitul Patel",
      role: "Senior Full Stack Data Scientist",
      company:"Decathlon Canada",
      text: "I've had a pleasure mentoring Pradeep in his data science journey at Data to Production. As Pradeep's mentor, I wanted to take a moment to offer some valuable recommendations and guidance as Pradeep embark on his journey in the exciting field of Data Science. First and foremost, I am incredibly impressed with his dedication and passion for Data Science. His commitment to learning and growing in this rapidly evolving field is evident, and I have no doubt that he will achieve great things! Always believe in yourself, stay motivated, and keep pushing forward. His dedication will undoubtedly lead him to achieve remarkable accomplishments.",
      // createdAt: "2023-05-15T00:00:00Z",
      image: "https://static.wixstatic.com/media/4eeb67_90b888b2aeb54d86a30af6766759e50e~mv2.png/v1/fill/w_257,h_257,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/mitul.png"
    },
    // {
    //   id: "testimonial-4",
    //   name: "Tushar Kanta Sahoo",
    //   role: "Data Analyst",
    //   company:"Deloitte",
    //   text: "Working with [Your Name] was an absolute pleasure. Their technical expertise and problem-solving skills are outstanding. They consistently deliver projects ahead of time and exceed expectations.",
    //   // createdAt: "2023-05-15T00:00:00Z",
    //   image: "https://media.licdn.com/dms/image/v2/D5603AQHWEZdZMt25BQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1726059394241?e=1732147200&v=beta&t=3gVvpVAjeVFP00KYO4Tifr0E_TWtpRoqdGfhrFXftIE"
    // },
  ],
};

export { testimonialsHeader, testimonials };

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "pradeep1.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, Data Analytics, Mentorship.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://medium.com/@pradeepchandrareddy95/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Bengaluru",
    locality: "Bengaluru",
    country: "India",
    region: "Karnataka",
    postalCode: "560001",
    streetAddress: "Bengaluru",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/rFHmKSi8J8eYLbxc6",
  },
  phoneSection: {
    title: "Phone",
    subtitle: "+91 1234567890",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
  // testimonialsHeader,
  // testimonials
};